<template>
    <div>
        <div v-if="authUserPermission['transaction-log-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-3">
                                <h3 class="mb-0">{{ tt('transaction_log') }}</h3>
                            </div>
                            <div class="col-2 text-right">
                                <!-- <input type="button" class="btn btn-sm btn-outline-dark" value="Export as Excel" disabled="true" v-if="btnExport.disabled"> -->
                                <a v-if="authUserPermission['transaction-log-export']" :href="apiUrl+'TransactionLog/export?date='+inputTransactionLog.date+'&token='+token" class="btn btn-sm btn-outline-dark" v-else>Export as Excel</a>
                            </div>
                            <div class="col-3">
                                <input class="form-control form-control-sm text-center border-radius-20" input-classes="form-control-sm" v-model="inputTransactionLog.search" :placeholder="tt('search_transaction_log')"  @keyup="filter"/>
                            </div>
                            <div class="col-3">
                                <base-input type="date" input-classes="form-control-sm border-radius-20" v-model="inputTransactionLog.date" :placeholder="tt('date')"/>
                            </div>
                            <div class="col-1 text-right">
                                <i class="fa fa-filter btn btn-sm btn-default" @click="search(inputTransactionLog.date == undefined ? dateNow : inputTransactionLog.date)"></i>
                            </div>
                        </div>
                    </div>
                    <div v-if="!onLoad">
                        <div class="text-center" v-if="!table.show">
                            <img src="/img/smartcat/data_is_empty.png" alt="Data Is Empty" style="width: 450px">
                            <p class="text-muted">{{ tt('no_transaction_log_in_smartcat') }}</p>
                        </div>
                        <div v-else>
                            <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="dataTransactionLog">
                                <el-table-column :label="tt('log_code')" :prop="tt('log_code')" min-width="140px" sortable>
                                    <template v-slot="{row}">
                                        {{row.log_code}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('log_type')" :prop="tt('log_type')" min-width="140px" sortable>
                                    <template v-slot="{row}">
                                        {{row.log_type}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('log_key')" :prop="tt('log_key')" min-width="170px" sortable>
                                    <template v-slot="{row}">
                                        {{row.log_key}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('url')" :prop="tt('url')" min-width="170px" sortable>
                                    <template v-slot="{row}">
                                        {{row.url}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('ip')" :prop="tt('ip')" min-width="170px" sortable>
                                    <template v-slot="{row}">
                                        {{row.ip}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('user_agent')" :prop="tt('user_agent')" min-width="170px" sortable>
                                    <template v-slot="{row}">
                                        {{row.user_agent}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('message')" :prop="tt('message')" min-width="170px" sortable>
                                    <template v-slot="{row}">
                                        {{row.message}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('note')" :prop="tt('note')" min-width="170px" sortable>
                                    <template v-slot="{row}">
                                        {{row.note}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('executed_by')" :prop="tt('executed_by')" min-width="170px" sortable>
                                    <template v-slot="{row}">
                                        {{row.executed_by}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('result')" :prop="tt('result')" min-width="170px" sortable>
                                    <template v-slot="{row}">
                                        {{row.result}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="200px" sortable>
                                    <template v-slot="{row}">
                                        {{row.created_at}}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <page-loading v-else/>
                    <div class="card-footer">
                        <span class="text-muted float-right">
                            <small>{{ tt('this_transaction_log_has_been_taken_today') }}</small>
                        </span>
                    </div>
                </div>
            </div>
  <!--       <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="inputTransactionLog.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('table') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('table')" rules="required">
                    <el-select class="select-danger" v-model="inputTransactionLog.table" placeholder="Choose Table">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('column') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('column')" rules="required">
                    <el-select class="select-danger" v-model="inputTransactionLog.column" placeholder="Choose Column">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal> -->
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import transactionLog from '@/services/report/transactionLog.service';
    import baseApiUrl from '@/configs/config';

    export default {        
        data() {
            return {       
                onLoad: false,         
                form: {
                    add: true,
                    title: this.tt('add_table_column'),
                    show: false
                }, 
                table: {
                    show: true
                },
                btnExport: {
                    disabled: true
                },
                inputTransactionLog: {
                    date: '',
                    search: ''
                }  ,
                dataTransactionLog: [
                    {
                        ip: '',
                        log_code: '',
                        note: ''
                    }
                ],
                apiUrl: baseApiUrl.apiUrl,
                token: localStorage.token,
                dateNow: ''
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            let date = new Date();
            let month = date.getMonth()+1;
            if (month == 0 || month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9) {
                month = '0'+month;
            }
            this.dateNow = date.getFullYear()+'-'+month+'-'+date.getDate();
            this.inputTransactionLog.date = this.dateNow
            this.search(this.dateNow)
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            search(date) {
                let context = this;
                context.onLoad = true;
                Api(context, transactionLog.index({date:context.inputTransactionLog.date, search:context.inputTransactionLog.search})).onSuccess(function(response) {
                    context.dataTransactionLog = response.data.data.data
                    console.log(response.data.data.data)
                    context.table.show = true
                    context.btnExport.disabled = false
                }).onError(function(error) {         
                    if (error.response.status == 404) {
                        context.table.show = false
                        context.btnExport.disabled = true
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call();
            },
            exportExcel() {
                let context = this;
                Api(context, transactionLog.export({date:context.inputTransactionLog.date, token:localStorage.token})).onSuccess(function(response) {
                    context.dataTransactionLog = response.data.data.data
                    console.log(response.data.data.data)
                    context.table.show = true
                }).call();
            },
            changeDate() {
                alert('tes')
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_table_column');
                this.form.show = true;
            },
            edit(id) {
                this.form.add = false;
                this.form.title = this.tt('edit_table_column');
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog("Are you sure to delete this data?").then((result) => {
                    if (result.value) {
                        this.alertDialog('success', 'Successed delete data');
                    } else {
                        this.alertDialog('error', 'Failed delete data');
                    }
                })
            },
            filter() {
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.search(this.inputTransactionLog.date)
                }, 100);
            },
        }   
    };
</script>
<style></style>
